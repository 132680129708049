define('hundredwebsites/controllers/websites/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    fund: Ember.computed("model.websites.@each.fund", function () {
      const funds = this.get('model').getEach('fund');
      const sum = funds.reduce((a, b) => a + b, 0);
      return sum;
    }),

    percentage: Ember.computed('fund', function () {
      const fund = this.get('fund');
      const percentage = fund / 100000 * 100;
      return percentage;
    })
  });
});