define('hundredwebsites/router', ['exports', 'hundredwebsites/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('about');
    this.route('contact');
    this.route('request');

    this.route('admin', function () {
      this.route('request-info');
    });

    this.route('websites', function () {
      this.route('new');
      this.route('edit', { path: '/:website_id/edit' });
    });
  });

  exports.default = Router;
});