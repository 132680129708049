define('hundredwebsites/controllers/contact', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    proceed: '',
    name: '',
    emailAddress: '',
    responseMessage: '',
    message: '',
    headerMessage: 'Send Me a Message',

    isValidEmail: Ember.computed.match('emailAddress', /^.+@.+\..+$/),
    isDisabled: Ember.computed.not('isValid'),
    isLongEnough: Ember.computed.gte("message.length", 5),
    isNameLongEnough: Ember.computed.gte("message.length", 1),
    isReCaptchaResponseTrue: Ember.computed.equal('proceed', true),

    isValid: Ember.computed.and('isValidEmail', 'isLongEnough', 'isNameLongEnough', 'isReCaptchaResponseTrue'),

    clearForm() {
      this.set('responseMessage', `We got your message and we’ll get in touch soon`);
      this.set('emailAddress', '');
      this.set('message', '');
      this.set('name', '');
    },

    actions: {
      send() {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const day = new Date().getUTCDate();
        const now = new Date();
        const thisMonth = months[now.getMonth()];

        const submittedForm = {
          name: this.get('name'),
          emailAddress: this.get('emailAddress'),
          message: this.get('message'),
          timestamp: thisMonth + " " + day
        };

        const newContact = this.store.createRecord('contact', submittedForm);
        newContact.save().then(function () {});
        this.clearForm();
      },

      onCaptchaResolved(reCaptchaResponse) {
        this.set('proceed', true);
        this.get('model').set('reCaptchaResponse', reCaptchaResponse);
      }
    }

  });
});